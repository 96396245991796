import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'cmp-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
    standalone: true,
    imports: [NgIf],
})
export class ContactComponent implements OnInit, OnChanges {
  /**
   * Icon
   */
  @Input() icon: string;
  /**
   * Logo
   */
  @Input() logo: string;
  /**
   * Title
   */
  @Input() title: string;
  /**
   * Text
   */
  @Input() text: string;
  /**
   * Link (phone or mail)
   */
  @Input() link: string;
  /**
   * Link type (phone, mail or link)
   */
  @Input() linkType: string;
  /**
   * Link text
   */
  @Input() linkText?: string;
  /**
   * Concat of mail or phone to direct link
   */
  contact: string;

  constructor() {}

  ngOnInit() {
    const mailto: string = `mailto:${this.link}`;
    const telTo: string = `tel:${this.link}`;
    if (this.linkType !== 'link') {
      this.contact = this.linkType === 'email' ? mailto : telTo;
    } else {
      this.contact = this.link;
    }
  }

  ngOnChanges() {
    this.ngOnInit();
  }
}
