<div class="contact-wrapper">
  <div class="circle mx-auto" *ngIf="icon">
    <div class="icon-custom">
      <fa-icon [icon]="icon" size="3x"></fa-icon>
    </div>
  </div>

  <div class="mx-auto logo-contact" *ngIf="logo">
    <img class="logo-contact-image" [src]="logo" alt="'Logo image'" />
  </div>

  <div class="text-align">
    <b>{{ title }}</b>
  </div>
  <div class="text-align">{{ text }}</div>
  <a *ngIf="linkType === 'link'" [href]="contact">{{ linkText }}</a>
  <a *ngIf="linkType !== 'link'" [href]="contact">{{ link }}</a>
</div>
